<template>
  <div>
    <s-modal
      v-model="showModal"
      :title="title"
      cancel-title="Fechar"
      no-close-on-backdrop
      no-close-on-esc
      ok-title="Fechar"
      size="xl"
      fullscreen
      scrollable
      ok-only
      @change="onHide"
    >
      <div>
        <b-tabs>
          <b-tab title="Informações">
            <b-row>
              <b-col md="12" sm="12">
                <b-row>
                  <b-col sm="12">
                    <b-card>
                      <b-row>

                        <b-col
                          cols="12"
                          xl="12"
                        >
                          <table>
                            <tr>
                              <th class="w-15">
                                <feather-icon
                                  icon="AtSignIcon"
                                  class="mr-75"
                                />
                                <span class="font-weight-bold">Serial</span>
                              </th>
                              <td class="pb-50 ">
                                {{ device.serial }}
                              </td>
                              <th class="pb-50">
                                <feather-icon
                                  icon="Edit2Icon"
                                  class="mr-75"
                                />
                                <span class="font-weight-bold">Tem Licença</span>
                              </th>
                              <td class="pb-50">
                                {{ device.has_license ? 'Sim' : 'Não' }}
                              </td>
                            </tr>
                            <tr>
                              <th class="pb-50">
                                <feather-icon
                                  icon="CalendarIcon"
                                  class="mr-75"
                                />
                                <span class="font-weight-bold">Ultima Sessão</span>
                              </th>
                              <td class="pb-50 text-capitalize">
                                {{ device.last_session | moment('DD/MM/YYYY') }}
                              </td>
                              <th class="pb-50  w-25">
                                <feather-icon
                                  icon="Edit2Icon"
                                  class="mr-75"
                                />
                                <span class="font-weight-bold">Em monitoramento</span>
                              </th>
                              <td class="pb-50 w-25">
                                {{ device.in_monitoring ? 'Sim' : 'Não' }}
                              </td>
                            </tr>
                            <tr>
                              <th class="pb-50">
                                <feather-icon
                                  icon="StarIcon"
                                  class="mr-75"
                                />
                                <span class="font-weight-bold">Situação</span>
                              </th>
                              <td class="pb-50 text-capitalize">
                                {{ device.situation.text || '' }}
                              </td>
                              <th>
                                <feather-icon
                                  icon="ServerIcon"
                                  class="mr-75"
                                />
                                <span class="font-weight-bold">Versão da extensão</span>
                              </th>
                              <td class="pb-50 text-capitalize">
                                {{ device.app_version || '' }}
                              </td>
                            </tr>
                            <tr>
                              <th class="pb-50">
                                <feather-icon
                                  icon="StarIcon"
                                  class="mr-75"
                                />
                                <span class="font-weight-bold">Data de Aprovisionamento</span>
                              </th>
                              <td class="pb-50 text-capitalize">
                                {{ device.provisioning_date | moment('DD/MM/YYYY hh:mm:ss') }}
                              </td>
                            </tr>
                            <tr>
                              <th class="pb-50">
                                <feather-icon
                                  icon="FlagIcon"
                                  class="mr-75"
                                />
                                <span class="font-weight-bold">Cidade</span>
                              </th>
                              <td class="pb-50">
                                {{ device.city }}
                              </td>
                            </tr>
                            <tr>
                              <th>
                                <feather-icon
                                  icon="CalendarIcon"
                                  class="mr-75"
                                />
                                <span class="font-weight-bold">Dias sem Uso</span>
                              </th>
                              <td>
                                {{ device.unused_days }}
                              </td>
                            </tr>
                            <tr>
                              <th>
                                <feather-icon
                                  icon="MapPinIcon"
                                  class="mr-75"
                                />
                                <span class="font-weight-bold">Localização</span>
                              </th>
                              <td>
                                <a :href="device.url_location" target="_blank">{{ device.url_location }}</a>
                                <div v-if="device.in_monitoring" class="cursor-pointer" @click="showLocationHistory">
                                  <feather-icon icon="MapPinIcon" />
                                  Historíco de Localização
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <th>
                                <feather-icon
                                  icon="CalendarIcon"
                                  class="mr-75"
                                />
                                <span class="font-weight-bold">Última Sincronização</span>
                              </th>
                              <td>
                                {{ device.last_sync | moment('DD/MM/YYYY hh:mm:ss') }}
                              </td>
                            </tr>
                          </table>
                        </b-col>
                      </b-row>
                    </b-card>
                  </b-col>
                  <b-col sm="12" />
                </b-row>
                <b-row>
                  <b-col sm="6">
                    <b-card>
                      <b-card-title>Outros Dados</b-card-title>
                      <b-row>
                        <b-col
                          cols="12"
                          xl="12"
                        >
                          <table class="w-100">
                            <tr>
                              <th class="pb-50 w-25 text-nowrap">
                                <feather-icon
                                  icon="DiscIcon"
                                  class="mr-75"
                                />
                                <span class="font-weight-bold">Uso do Disco ({{
                                  device.device_data.diskSpaceUsage.capacityBytes
                                }}GB)</span>
                              </th>
                              <td>
                                <b-progress :value="device.device_data.diskSpaceUsage.usedBytes"
                                            :max="device.device_data.diskSpaceUsage.capacityBytes"
                                            height="20px"
                                            :show-value="false"
                                            label="asdasdad"
                                            :precision="2" show-progress
                                />
                              </td>
                            </tr>
                            <tr>
                              <th>
                                <feather-icon
                                  icon="AlignJustifyIcon"
                                  class="mr-75"
                                />
                                <span class="font-weight-bold">Observações</span>
                              </th>
                              <td>
                                {{ device.device_data.notes }}
                              </td>
                            </tr>
                            <tr>
                              <th>
                                <feather-icon
                                  icon="AlignJustifyIcon"
                                  class="mr-75"
                                />
                                <span class="font-weight-bold">Usuário</span>
                              </th>
                              <td>
                                {{ device.device_data.annotatedUser }}
                              </td>
                            </tr>
                            <tr>
                              <th>
                                <feather-icon
                                  icon="AlignJustifyIcon"
                                  class="mr-75"
                                />
                                <span class="font-weight-bold">Id do Recurso</span>
                              </th>
                              <td>
                                {{ device.device_data.annotatedAssetId }}
                              </td>
                            </tr>
                            <tr>
                              <th>
                                <feather-icon
                                  icon="AlignJustifyIcon"
                                  class="mr-75"
                                />
                                <span class="font-weight-bold">Local </span>
                              </th>
                              <td>
                                {{ device.device_data.annotatedLocation }}
                              </td>
                            </tr>
                            <tr>
                              <th class="text-nowrap">
                                <feather-icon
                                  icon="CalendarIcon"
                                  class="mr-75"
                                />
                                <span class="font-weight-bold">Atualizações Automáticas até </span>
                              </th>
                              <td>
                                <b-row>
                                  <b-col>
                                    <span v-if="device.device_data.autoUpdateThrough" />
                                    {{ device.device_data.autoUpdateThrough | moment('DD/MM/YYYY') }}
                                  </b-col>
                                </b-row>
                              </td>
                            </tr>
                          </table>
                        </b-col>
                      </b-row>
                    </b-card>
                  </b-col>
                </b-row>
              </b-col>
              <b-col md="4" sm="12" rowspan="3" />
            </b-row>
          </b-tab>
          <b-tab title="Sites Acessados">
            <b-row v-for="(url, i) of device.last_opened_tabs" :key="i">
              <b-col> {{ url }}</b-col>
            </b-row>
          </b-tab>
          <b-tab title="Arquivos" style="height:500px">
            <device-medias v-if="device.uuid" :device="device.uuid" />
          </b-tab>
        </b-tabs>
      </div>
    </s-modal>
    <device-location-history v-model="device.uuid" :show="showDeviceLocation" @onClose="onCloseDeviceLocation" />
  </div>
</template>

<script>

import DeviceStatus from '@/variables/DeviceStatus'
import DeviceLocationHistory from '@/components/app/Device/DeviceLocationHistory.vue'
import DeviceMedias from '@/components/app/Device/DeviceMedias.vue'
import { BProgress } from 'bootstrap-vue'

export default {
  name: 'DeviceDetail',
  components: {
    BProgress,
    DeviceMedias,
    DeviceLocationHistory,
  },
  props: {
    value: {
      type: String,
      required: false,
      default: '',
    },
    title: {
      type: String,
      required: false,
      default: 'Detalhes do Dispositivo',
    },
    show: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      showDeviceLocation: false,
      showModal: false,
      device: this.getEmptyDevice(),
      token: '',
      images: [],
      audios: [],
    }
  },
  watch: {
    show(value) {
      this.showModal = value
      if (value) {
        this.getDevice()
      }
    },
  },
  mounted() {
    this.getDevice()
    this.token = window.sessionStorage.getItem('accessToken')
  },
  methods: {
    onHide() {
      this.$emit('onClose', true)
    },
    async getDevice() {
      if (this.value === '') {
        this.device = this.getEmptyDevice()
        return
      }
      const response = await this.$http.get(`devices/${this.value}`)
      if (response.error) {
        this.$notify.error(response.error_message)
        return
      }
      this.device = response
      this.device.situation = DeviceStatus.find(item => item.value === response.situation)
      if (!this.device.device_data) {
        this.device.device_data = {
          diskSpaceUsage: { capacityBytes: 0, usedBytes: 0 },
        }
      }
      this.device.device_data.diskSpaceUsage.capacityBytes = this.bytesParaGigabits(response.device_data.diskSpaceUsage.capacityBytes)
      this.device.device_data.diskSpaceUsage.usedBytes = this.bytesParaGigabits(response.device_data.diskSpaceUsage.usedBytes)

    },
    getEmptyDevice() {
      return {
        situation: {
          text: '',
        },
        images: [],
        audios: [],
        last_sync: new Date(),
        device_data: {
          notes: '',
          annotatedAssetId: '',
          annotatedLocation: '',
          annotatedUser: '',
          autoUpdateThrough: null,
          diskSpaceUsage: {
            capacityBytes: 0,
            usedBytes: '0',
          },
        },
      }
    },
    getImageUrl(media) {
      return `${media.path}?token=${this.token}`
    },
    showLocationHistory() {
      this.showDeviceLocation = true
    },

    onCloseDeviceLocation() {
      this.showDeviceLocation = false
    },
    bytesParaGigabits(bytes) {
      const BYTES_POR_BIT = 8 // 1 byte = 8 bits
      const BITS_POR_GIGABIT = 1e9 // 1 gigabit = 10^9 bits
      return ((bytes * BYTES_POR_BIT) / BITS_POR_GIGABIT).toFixed(2)
    },
  },

}
</script>

<style scoped>

th {
  padding-bottom: 10px;
  max-width: 300px;
  text-wrap: nowrap;
}

th:after {
  content: ":"
}

td {
  background-color: #F9F9F9;
  padding-bottom: 10px;
  padding-left: 15px;
}
</style>
